@import "../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.experience-dynamic-sitemap,
.experience-dynamic-sitemap1r3c {
    a {
        text-decoration: none;
    }
}

.experience-dynamic-sitemap li,
.search-results .experience-dynamic-sitemap__ul--style li {
    list-style-type: none;
}

.nav-categories-sitemap_item,
.nav-categories-sitemap_item_subcategories_item {
    list-style-type: none;
}

.experience-dynamic-sitemap__ul--style {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    list-style-type: none;

    .experience-dynamic-sitemap__li--style {
        list-style-type: none;
        padding: 0 0 1rem;
        @include media-breakpoint-down(sm) {
            padding: 0 0 .5rem;
        }

        .experience-dynamic-sitemap__a {
            display: block;
            border-bottom: 1px solid #000;
            margin-bottom: 1em;
            font-size: 1.125rem !important;
            line-height: 1.5 !important;
        }

        .experience-dynamic-sitemap__a--style {
            padding: 0 0 1rem;
            text-decoration: none;
            font-size: 1rem;
            line-height: 1;
            font-family: $base-font-family;
            font-style: normal;

            &:hover {
                text-decoration: underline;
                text-decoration-color: #000;
            }

            &:focus,
            &:active {
                text-decoration: underline;
                font-weight: 700;
            }
        }

        .experience-dynamic-sitemap__inner-ul--style {
            padding: 0;
            list-style-type: none;
        }
    }
}

.experience-dynamic-sitemap__master-column--style {
    padding-top: 30px;
    padding-bottom: 30px;

    .experience-dynamic-sitemap__a--style {
        font-family: $base-font-family;
        font-style: normal;
        color: #000;
        padding: 0.5rem 0;
        font-size: 1rem;
        line-height: 1;

        &:not(.experience-dynamic-sitemap__a) {
            letter-spacing: 0.01em;
        }

        &:hover {
            text-decoration: underline;
            text-decoration-color: #000;
        }

        &:focus,
        &:active {
            text-decoration: underline;
            font-weight: 700;
        }
    }

    .experience-dynamic-sitemap__a--style:not(.font-weight-bold) {
        font-weight: $base-font-weight;
    }

    .experience-dynamic-sitemap__a {
        font-size: 1.125rem !important;
        line-height: 1.2 !important;

        &::after {
            content: "";
            display: block;
            width: 70%;
            margin-top: 15px;
            margin-bottom: 15px;
            margin-right: 0;
            margin-left: 0;
            border-bottom: 1px solid #000;
        }
    }

    .experience-dynamic-sitemap__column--style {
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

@include media-breakpoint-down(sm) {
    .experience-dynamic-sitemap__master-column--style {
        .experience-dynamic-sitemap__a {
            &::after {
                margin: auto;
                width: 100%;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .experience-dynamic-sitemap__ul--style {
        flex-direction: column;
        text-align: center;
    }

    .experience-dynamic-sitemap__master-column--style {
        .experience-dynamic-sitemap__a {
            &::after {
                margin-top: 15px;
                margin-bottom: 15px;
                margin-right: 0;
                margin-left: 0;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .experience-dynamic-sitemap__ul--style {
        flex-direction: row;
        text-align: left;
    }
}
